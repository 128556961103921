<template>
  <div>
    <refreshbtn @refesh="refesh" :isBetWeen="true">
      <template slot="search-btns">
        <el-button type="success" size="small" v-has="'/api/video/sets/create'" @click="addFromMethod" ref="btn"
        >添加</el-button>
      </template>
    </refreshbtn>
    <videoTable
      :headerList="headerList"
      :tableData="tableData"
      @editData="editData"
      :total="total"
      :pages="search.page"
      :delFIle="delUrl"
      :isLook="false"
      :editUrl="'/api/video/sets/update'"
      :deleteUrl="'/api/video/sets/destroy'"
      ref="table"
    />
    <videoDialog
      :data="editOpen"
      @clearData="clearData"
      @pushData="updateData"
      :parentsList="parentsList"
      ref="dialog"
      :isLook="false"
    ></videoDialog>
  </div>
</template>

<script>
import videoTable from "./components/videoTable";
import videoDialog from "./components/videoDialog";
import API from "@/api";

export default {
  name: "continuous",
  components: { videoTable, videoDialog },
  data() {
    return {
      search: {
        page: 1,
        size: 10,
      },
      total: 0,
      axiosTile: "/sets",
      delUrl: "/api/video/sets/destroy",
      editOpen: {},
      headerList: [
        {
          key: "ID",
          value: "id",
          align:'center',
          width:'50'
        },
        {
          key: "标题",
          value: "title",
        },
        {
          key: "封面图",
          value: "cover_image",
          type: "image",
          align:'center',
        },
        {
          key: "排序值",
          value: "score_count",
          align:'center',
        },
        {
          key: "下属视频数",
          value: "length",
          align:'center',
        },
        {
          key: "总集数",
          value: "all_video_count",
          align:'center',
        },
        {
          key: "是否完结",
          value: "is_over",
          type:'Boolean',
          align:'center',
        },
        {
          key: "评分人数",
          value: "sort_order",
          align:'center',
        },
        {
          key: "评分",
          value: "score",
          align:'center',
        },
        {
          key: "状态",
          value: "status",
          type: "dictionary",
          width:'100',
          align:'center',
          dictionary: [
            {
              key: 0,
              value: "准备中",
            },
            {
              key: 7,
              value: "弃用",
            },
            {
              key: 10,
              value: "完成&可使用",
            },
          ],
        },
        {
          key: "创建时间",
          value: "create_time",
          type:'time',
          align:'center',
          width:'180'
        },
        {
          key: "更新时间",
          value: "update_time",
          type:'time',
          align:'center',
          width:'180'
        },
      ],
      tableData: [],
      parentsList:[],
      isRefesh:true

    };
  },
  created() {
    let listRule = '/api/video/sets/list'
    let obj = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('btnPower'))));
    if(obj[listRule]){
      this.isRefesh = true
      this.getVideoList();
    }else{
      this.isRefesh = false 
    }
    this.getSetsList()
  },
  methods: {
    refesh(){
      this.isRefesh?this.getVideoList():''
    },
    async getVideoList(page) {
      this.$nextTick(()=>{
        this.$refs.table.loading = true;
      })
      this.search.page = page || 1
      const { data,code } = await API.getVideoList(this.search, this.axiosTile);
      if(!code){
        this.tableData = data.data;
        this.total = data.pagination.count
        this.$refs.table.loading = false;
      }else{
        this.tableData = [];
        this.total = 0
        this.$refs.table.loading = false;
      }
      
    },
    clearData() {
      this.editOpen = {};
      this.$refs.dialog.openDialog = false;
      // this.$refs.table.loading = true;
    },
    async updateData(data) {
      if (data.id) {
        const { code,msg } = await API.updateMediumList(data);
        if (!code) {
          this.$message.success("编辑视频成功");
          this.getVideoList(this.search.page)
          this.$refs.dialog.loading = false
          this.$refs.dialog.openDialog = false;
          this.editOpen = {};
        } else {
            this.$message.error(msg);
            this.$refs.dialog.loading = false
            this.$refs.dialog.openDialog = false;
            this.editOpen = {};
          }
      } else {
        API.creatMediumList(data).then((res) => {
          if (!res.code) {
            this.$message.success("新增短视频成功");
            this.getVideoList(this.search.page)
            this.$refs.dialog.loading = false
            this.$refs.dialog.openDialog = false;
            this.editOpen = {};
          } else {
            this.$message.error(res.msg);
            this.$refs.dialog.loading = false
            this.$refs.dialog.openDialog = false;
            this.editOpen = {};
          }
        });
      }
    },
    editData(data) {
      this.editOpen = JSON.parse(JSON.stringify(data));
      this.$refs.dialog.openDialog = true;
      this.$refs.dialog.delUrl = this.delUrl;
    },
    // 新增
    addFromMethod() {
      // {this.$refs.dialog.validate()}
      // this.$refs.dialog.rules.validate((valid) => {
      //   if (valid) {
      //     this.$refs.dialog.openDialog = true;
      //     this.getVideoList();
      //   } else {
      //     return false;
      //   }
      // });
      // this.editOpen = data;
      this.$refs.dialog.openDialog = true;
      this.$refs.table.loading = false;
      this.$refs.dialog.delUrl = this.delUrl;
    },
    // 获取连载视频--连载视频弹框父级字段  视频管理--连载名称字段
    async getSetsList(){
      await API.getVideoList({page: 1,size: 9999}, "/sets").then((res)=>{
        if(!res.code && res.data.data.length){
          this.parentsList = [{id:0,title:'无'}].concat(res.data.data)
        }else{
          this.parentsList = [{id:0,title:'无'}]
        }
      })
    },
    async changeSize(pageSize) {
      this.search.page = 1;
      this.search.size = pageSize;
      this.getVideoList();
    }
  },
};
</script>

<style scoped>
</style>
